/* Start of Style for Drop Down */

$select-color: #FFFFFF;
$height: 30px;

.select {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $height;
  background: $select-color;
  overflow: auto;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: solid 1px #BCBCBC !important;
    background: $select-color;
    background-image: none;
  }

  select {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0px 0 0 .5em;
    color: gray;
    cursor: pointer;
  }

  select::-ms-expand {
    display: none;
  }
}

.select::after {
  content: '\25BC';
  position: absolute;
  line-height: $height;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0 1em;
  pointer-events: none;
}

.select:hover::after {
  color: #22A0D2;
  cursor: pointer;
}

.select::after {
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}

/* End of Style for Drop Down */